.code-block {
  display: block;
  width: auto;
  position: relative;
  overflow-y: auto;
  background: #3c3f41;
  border-radius: 4px;
  border: 0 solid #3c3f41;
  color: #fff;
  font-family: Consolas, Menlo, Bitstream Vera Sans Mono, monospace, Powerline Symbols;
  line-height: 1.3em;
  white-space:pre-line;
  padding: 8px 8px;
  margin: 12px 0;
}
