// Override default variables before the import
$accordion-padding-y:                     0.1rem;
$accordion-padding-x:                     0.3rem;
$accordion-button-padding-y:              $accordion-padding-y;
$accordion-button-padding-x:              $accordion-padding-x;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

body {
  margin:0;
  margin-top:60px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  display: block;
}
.codeblock {
  position: relative;
  display: grid;
  margin-top: var(--margin-bottom-md);
  margin-bottom: var(--margin-bottom-md);
}

.header-menu {
  position: fixed;
    top: 0;
  height: 60px;
  z-index: 99;
}
.wrapper {
  width: 100%;

}






#content {
  width: 100%;
  padding: 0;
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  h1 {
    padding-top: 35px;
    margin-bottom: 20px;
  }
  h2 {
    padding-top: 35px;
    margin-bottom: 20px;
  }
  h3 {
    padding-top: 35px;
    margin-bottom: 20px;
  }
  .docs-sidebar {
    padding:5px;
    position: relative;
    .searchbar {
      width: 90%;
    }
  .docs_search_results {
    position: absolute;
    margin: 0 2px;
    width: 90%;
    max-height: 250px;
    height: auto;
    z-index: 999;
    overflow-y: auto;
  }
}

  @media (min-width: 992px){
  .docs-sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 0;
    z-index: 2;
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
  }

}

