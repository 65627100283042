
.osx-window {
  position: relative;
  background: #2b2b2b;
  border-radius: 4px;
  border: 0 solid #2b2b2b;
  border-top-width: 30px;
  color: #fff;
  font-family: Consolas, Menlo, Bitstream Vera Sans Mono, monospace, Powerline Symbols;
  line-height: 1.3em;
  font-size: 11px;
  white-space: pre;
  padding: 6px 2px;

  &:before {
    background-color: #fc2230;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fc2230, 1.5em 0 0 2px #fd9332, 3em 0 0 2px #7cc74c;
    content: "";
    display: block;
    height: 7px;
    left: 15px;
    position: absolute;
    top: -1.25em;
    width: 7px;
  }

  .osx-window-title {
    position: absolute;
    top: -1.80em;
    left: 50%;
    transform: translateX(-50%);
    color: #ddd;
    font-weight: bold;
  }
}

.osx-window {

  width: 100%;
  padding: 8px 8px;
  margin: 12px 0;
  display: flex;
  flex-direction: column;

  position: relative;

  .editor {
    padding: 6px 12px;
    flex: 1 1 0;
    overflow: auto;
    margin: 0;
    font-size: 13px;

    code {
      color: #a9b7c7;
    }
  }

  .divider {
    border-top: 3px solid #3c3f41;
    height: 0;
    width: 100%;
    flex: 0 0 auto;
  }
}
